



















































import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import router from "@/router";

export default Vue.extend({
  name: "renew-subscription-confirm-modal" as string,

  props: {
    modalId: Number as PropType<number>,
    payloadData: Object as PropType<object>,
    selectedVenueSubscription: Object as PropType<object>,
    isPost: Boolean as PropType<boolean>,
    subscriptionId: Number as PropType<number>,
  },

  data() {
    return {
      loader: false,
    };
  },

  computed: {
    // ...mapGetters("venueSubscriptionModule", {
    //   loader: "GET_LOADER",
    // }),
  },

  methods: {
    ...mapActions("venueModule", {
      setVenue: "FETCH_VENUE",
    }),
    ...mapGetters("venueSubscriptionModule", {
      getStripeSubscriptionResponse: "GET_STRIPE_SUBSCRIPTION_RESPONSE",
    }),
    ...mapActions("venueSubscriptionModule", {
      createVenueSubscriptionOnStripe: "CREATE_VENUE_SUBSCRIPTION_ON_STRIPE",
      createVenueSubscription: "CREATE_VENUE_SUBSCRIPTION",
      renewVenueSubscription: "RENEW_VENUE_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION",
    }),
    isSelectedSubscriptionIsCanceled(): boolean {
      return (
        this.selectedVenueSubscription.cancelledAt != null ||
        this.selectedVenueSubscription.subscriptionStatus === "canceled"
      );
    },
    isSelectedSubscriptionExpired(): boolean {
      let currentTimestamp = Math.floor(Date.now() / 1000) as number;
      let subscriptionEndTimestamp = parseInt(this.selectedVenueSubscription.subscriptionEndsAt) as number;

      if (this.selectedVenueSubscription.isTrial === true && currentTimestamp > subscriptionEndTimestamp) {
        return true;
      }

      return false;
    },
    async changeSubscriptionPlan(): Promise<void> {
      this.loader = true;
      if (this.isPost) {
        let payloadForUpdateSubscriptionOnCzoneBackend = this.payloadData;
        if (
          this.isSelectedSubscriptionIsCanceled() ||
          this.isSelectedSubscriptionExpired()
        ) {
          // Subscription is canceled, need to create new stripe subscription.
          await this.createVenueSubscriptionOnStripe(this.payloadData);
          const responseOfCreateStripeSubscription =
            this.getStripeSubscriptionResponse();
          payloadForUpdateSubscriptionOnCzoneBackend.subscriptionId =
            responseOfCreateStripeSubscription.stripeSubscriptionData.id;
        }

        await this.createVenueSubscription(
          payloadForUpdateSubscriptionOnCzoneBackend
        );
      } else {
        await this.renewVenueSubscription({
          payloadData: this.payloadData,
          resourceId: this.subscriptionId,
        });
      }

      this.loader = false;
      this.$bvModal.hide("renew-subscription-confirm-modal" + this.modalId);

      if (!this.isPost) {
        await this.setVenue();
        await this.setVenueSubscription(router.currentRoute.params.id);
        await this.setSubscriptionProducts();
      }
      setTimeout(function () {
        this.setVenueSubscription(router.currentRoute.params.id).bind(this);
        this.setSubscriptionProducts().bind(this);
      }, 3500);
      this.$router.push({
        path: "/subscription-plan/details/" + router.currentRoute.params.id,
        params: { id: router.currentRoute.params.id },
      });
    },
  },
});
