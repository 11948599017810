














import Vue, {PropType} from "vue";
import {VenueData} from "@/types";

export default Vue.extend({
  name: "general-info" as string,

  props: {
    venue: Object as PropType<VenueData>
  },

  methods: {
    insertVenueImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    }
  }
})
