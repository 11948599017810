













import Vue, {PropType} from "vue";
import {VenueData, VenueSubscriptionData} from "@/types";

export default Vue.extend({
  name: "streaming-method" as string,

  props: {
    venue: Object as PropType<VenueData>,
    subscriptionProduct: Object as PropType<VenueSubscriptionData>
  },

  computed: {
    streamingMethodText(): string {
      return this.streamingDeviceIncluded ? "Streaming device" : "Webstream";
    },
    streamingDeviceIncluded(): boolean {
      return this.subscriptionProduct.includedStreamingDevice;
    }
  }
})
