





























import Vue, {PropType} from "vue";
import { errorMessage } from "@/utils/messages-handlers";

export default Vue.extend({
  name: "audio-messages-info-modal" as string,

  props: {
    id: Number as PropType<number>,
    hasUserRolePermission: Boolean as PropType<boolean>,
    subscriptionProduct: Boolean as PropType<boolean>,
    subscriptionType: Number as PropType<number>
  },

  methods: {
    redirectToChangeSubscriptionPlan(): void {
      if(this.subscriptionProduct){
        errorMessage('This additional service is already ordered',5000)
      } else{
        this.$router.push("/subscription-plan/edit/" + this.id + '?audio_messages=true');
      }
    }
  }
})
