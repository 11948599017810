


























































































































































































import Vue, { PropType } from "vue";
import { VenueData, VenueSubscriptionData } from "@/types";
import ConfirmCancelSubscriptionModal from "@/components/modals/ConfirmCancelSubscriptionModal.vue";
import router from "@/router";
import RenewSubscriptionConfirmModal from "@/components/modals/RenewSubscriptionConfirmModal.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import TrialModal from "@/components/modals/TrialModal.vue";

export default Vue.extend({
  name: "subscription-plan" as string,

  components: {
    "confirm-cancel-subscription-modal": ConfirmCancelSubscriptionModal,
    "renew-subscription-confirm-modal": RenewSubscriptionConfirmModal,
    "trial-modal": TrialModal,
  },

  data() {
    return {
      payloadData: {} as object,
      randomId: Math.floor(Math.random() * 100000),
    };
  },

  props: {
    venue: Object as PropType<VenueData>,
    isPremium: Boolean as PropType<boolean>,
    isExpired: Boolean as PropType<boolean>,
    isIncomplete: Boolean as PropType<boolean>,
    isIncompleteExpired: Boolean as PropType<boolean>,
    subscriptionType: Number as PropType<number>,
    venueSubscription: Object as PropType<VenueSubscriptionData>,
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      isCorporate: "GET_IS_CORPORATE",
    }),
    ...mapGetters("userModule", {
      hasEditSubscriptionPermission: "GET_EDIT_SUBSCRIPTION_PERMISSION",
    }),
    subscriptionPlanText(): string {
      return this.isPremium ? "Premium" : "Basic";
    },
    isSubscriptionCanceled(): boolean {
      return (
        this.venueSubscription.cancelledAt != null ||
        this.venueSubscription.subscriptionStatus === "canceled"
      );
    },
    isSubscriptionInActive(): boolean {
      return (
        (this.venueSubscription.subscriptionStatus === "trialing" ||
          this.venueSubscription.subscriptionStatus === "active") &&
        this.venueSubscription.cancelledAt == null
      );
    },
    subscriptionExpirationDate(): string {
      if (!this.venueSubscription) return "";

      return this.formatDate(this.venueSubscription.subscriptionEndsAt);
    },
    subscriptionDatesRange(): string {
      if (!this.venueSubscription) return "";

      const startDate = this.formatDate(this.venueSubscription.startDate);
      const endDate = this.formatDate(
        this.venueSubscription.subscriptionEndsAt
      );

      return startDate + " - " + endDate;
    },
    subscriptionId(): number {
      return this.venueSubscription.id;
    },
    currentBillingPeriodDate(): string {
      return (
        this.formatDate(this.venueSubscription.currentPeriodStartedAt) +
        " - " +
        this.formatDate(this.venueSubscription.subscriptionEndsAt)
      );
    },
    isTrial(): boolean {
      if (
        this.venueSubscription.subscriptionStatus === "trialing" ||
        this.venueSubscription.isTrial === true
      ) {
        return true;
      }

      return false;
    },
    isExpiredOrIncomplete(): boolean {
      return this.isExpired || this.isIncomplete || this.isIncompleteExpired;
    },
  },

  methods: {
    changeSubscriptionPlan(): void {
      this.$router.push({
        path: "/subscription-plan/edit/" + this.venue.id.toString(),
        params: { id: this.venue.id.toString() },
      });
    },
    // renewSubscriptionPlan(): void {
    //   if (
    //     this.venueSubscription.subscriptionEndsAt >= moment().unix() &&
    //     this.venue.state !== 4
    //   ) {
    //     this.openRenewConfirmModal();
    //   } else {
    //     this.$router.push({
    //       path: '/subscription-plan/edit/' + this.venue.id.toString(),
    //       params: { id: this.venue.id.toString() }
    //     })
    //   }
    //   },
    renewSubscriptionPlan(): void {
      if (
        this.venueSubscription.subscriptionEndsAt >= moment().unix() &&
        this.venue.state !== 4
      ) {
        this.openRenewConfirmModal();
      } else {
        this.redirectToRenewSubscription();
      }
    },
    redirectToRenewSubscription(): void {
      this.$router.push({
        path: "/subscription-plan/renew/" + this.venue.id.toString(),
        params: {
          id: this.venue.id.toString(),
          subscription: this.venueSubscription,
        },
      });
    },
    formatDate(value: string): string {
      const timestamp = Number(value) * 1000;
      const date = new Date(timestamp);

      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },
    async openRenewConfirmModal(): Promise<void> {
      this.payloadData = {
        isRenew: true,
      };
      this.$bvModal.show("renew-subscription-confirm-modal" + this.randomId);
    },
    showTrialModal(): void {
      this.$bvModal.show("trial-modal-subscription-plan");
    },
    onPressedRenewExpiredOrIncomplete(): void {
      if (this.isTrial && this.isExpired) {
        this.showTrialModal();
      } else {
        this.redirectToRenewSubscription();
      }
    },
  },
  // formatDate(value: string): string {
  //   const timestamp = Number(value) * 1000;
  //   const date = new Date(timestamp);

  //   return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  // },
  // async openRenewConfirmModal(): Promise<void> {
  //   this.payloadData = {
  //     isRenew: true,
  //   };
  //   this.$bvModal.show("renew-subscription-confirm-modal" + this.randomId);
  // },
  //},
});
