



































import Vue, {PropType} from "vue";
import {VenueData, VenueSubscriptionData} from "@/types";
import AudioMessagesInfoModal from "@/components/modals/AudioMessagesInfoModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "additional-services" as string,

  components: {
    "audio-messages-info-modal": AudioMessagesInfoModal
  },

  props: {
    venue: Object as PropType<VenueData>,
    subscriptionProduct: Object as PropType<VenueSubscriptionData>,
    subscriptionType: Number as PropType<number>,
  },

  computed: {
    ...mapGetters("userModule", {
      hasUserRolePermission: "GET_EDIT_SUBSCRIPTION_PERMISSION"
    }),
    audioMessagesIncluded(): boolean {
      return !!this.subscriptionProduct.additionalServices;
    }
  },

  methods: {
    openAudioMessagesInfoModal(): void {
      this.$bvModal.show("audio-messages-info-modal");
    }
  }
})
