






































import Vue from "vue";
import {mapActions} from "vuex";
import router from '@/router';

export default Vue.extend({
  name: "delete-venue-confirm-modal" as string,

  methods: {
    ...mapActions("venueModule", {
      deleteVenue: "DELETE_VENUE"
    }),
    async softDeleteVenue(): Promise<void> {
      await this.deleteVenue(router.currentRoute.params.id);
      localStorage.setItem('venue_id', '');
      this.$bvModal.hide("delete-venue-confirm-modal");
      router.push("/subscription-plan");
    }
  }
})
