




























































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import GeneralInfo from "@/components/venue-subscription/GeneralInfo.vue";
import SubscriptionPlan from "@/components/venue-subscription/SubscriptionPlan.vue";
import StreamingMethod from "@/components/venue-subscription/StreamingMethod.vue";
import AdditionalServices from "@/components/venue-subscription/AdditionalServices.vue";
import DeleteVenueConfirmModal from "@/components/modals/DeleteVenueConfirmModal.vue";
import BackButton from "@/components/back-button.vue";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import {SubscriptionProductsData} from "@/types";

export default Vue.extend({
  name: "subscription-details" as string,

  components: {
    Layout,
    "general-info": GeneralInfo,
    "subscription-plan": SubscriptionPlan,
    "streaming-method": StreamingMethod,
    "additional-services": AdditionalServices,
    "delete-venue-confirm-modal": DeleteVenueConfirmModal,
    "back-button": BackButton
  },

  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 7, venueId: router.currentRoute.params.id });
    await this.checkLoggedUserRolePermission({permissionId: 6, venueId: router.currentRoute.params.id});
  },

  async mounted(): Promise<void> {
    await this.setVenue();
    await this.setVenueSubscription(router.currentRoute.params.id);
    await this.setSubscriptionProducts();
    if (!!this.venue.image_last_updated) {
      await this.setImage({id: router.currentRoute.params.id, imageType: "clientzone_venue"});
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      venue: "GET_SELECTED_VENUE"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("venueSubscriptionModule", {
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      selectedVenueSubscription: "GET_SELECTED_VENUE_SUBSCRIPTION"
    }),
    ...mapGetters('userModule', {
      hasAppFeaturesPermissions: "GET_APP_FEATURES_PERMISSION"
    }),
    isSubscriptionProductPremium(): boolean {
      if (!this.subscriptionProducts.length || !this.selectedVenueSubscription.stripeProductWithPriceId) return false;
      const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId);

      return product.songorooPackageType === 2;
    },
    getSubscriptionType(): number {
      if (!this.selectedVenueSubscription) return -1;

      const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId);
      if (product){
        return product.songorooPackageType;
      } else if(this.selectedVenueSubscription.isCorporate && !this.selectedVenueSubscription.stripeProductWithPriceId){
        return 3
      } else {
        return -1;
      }
    },
    isSubscriptionExpired(): boolean {
      const currentDate = Math.floor(Date.now() / 1000);
      const expiredDate = Number(this.selectedVenueSubscription.subscriptionEndsAt);

      return currentDate >= expiredDate || this.selectedVenueSubscription.subscriptionStatus === "canceled";
    },
    isSubscriptionIncomplete(): boolean {
      if (!this.selectedVenueSubscription) return false;

      return this.selectedVenueSubscription.subscriptionStatus == "incomplete";
    },
    isSubscriptionIncompleteExpired(): boolean {
      if (!this.selectedVenueSubscription) return false;

      return this.selectedVenueSubscription.subscriptionStatus == "incomplete_expired";
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setVenue: "FETCH_VENUE"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueSubscriptionModule", {
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION"
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    goToVenueAppFeaturesDetail(): void {
      this.$router.push({
        path: '/app-features-setup/' + this.venue.id.toString(),
        params: { id: this.venue.id.toString() }
      })
    },
    openDeleteVenueModal(): void {
      this.$bvModal.show("delete-venue-confirm-modal");
    },
  },

  watch: {
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/subscription-plan");
        }
      }
    }
  }
})
