





























import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "confirm-cancel-subscription-modal" as string,

  props: {
    expirationDate: String as PropType<string>,
    subscriptionResourceId: Number as PropType<number>,
    venueId: Number as PropType<number>
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      loader: "GET_LOADER"
    })
  },

  methods: {
    ...mapActions("venueModule", {
      setVenue: "FETCH_VENUE"
    }),
    ...mapActions("venueSubscriptionModule", {
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION",
      cancelVenueSubscription: "CANCEL_VENUE_SUBSCRIPTION"
    }),
    async cancelSubscription(): Promise<void> {
      await this.cancelVenueSubscription(this.subscriptionResourceId);
      this.$bvModal.hide('confirm-cancel-subscription-modal');

      await this.setVenue();
      await this.setVenueSubscription(this.venueId);
      await this.setSubscriptionProducts();
    }
  }
})
